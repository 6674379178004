main {
    min-height: 83vh;
}

.homepage_card {
    margin-bottom: 30px;
}

.banner_heading {
    text-align: center;
    padding: 10px 0;
    margin-top: 30px;
}

.Cards_for_dash {
    height: auto;
    max-height: 550px;
    width: auto;
    max-width: 550px;
}

.single-client-info{
    text-align: center;
    padding: 10px 0;
    margin-top: 30px;
}

.processImages{
    width: 400px;
    height: 500px;
    margin: 15px 0 15px 0;
}

.workOrderRow{
    padding: 20px 0;
    transition: 0.3s;
    background-color: white;
    /*border-bottom: 1px solid gray;*/
}


.workOrderRow:hover {
    background-color: lightblue;
    transition: 0.3s;
    border: none;
    color: black;
    padding: 16px 32px;
    font-size: 16px;
    margin: 4px 2px;
}

.AddHourRow{
    padding: 15px 0 0 0;
}

.AddHourCol{
    padding: 0 5px 0 5px;
}

.HourPerRow{
    padding: 20px 0 20px 0;
}

.HoursSpan{
    padding-bottom: 20px;
}

.perposalInvoiceHeaders{
    text-align: center;
    padding: 15px 0 15px 0;
}

.loadingSpinner{
    margin: auto;
    width: 50%;
    padding: 10px;
}

.reports_nav_heading{
    padding: 15px 0 20px 0;
}

.statusReportHeader{
    padding: 15px 0 25px 0;
    text-align: center;
}

.newPaymentSpan{
    padding:10px;
    margin:10px 10px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    border-radius:10px;
    background-color: black;
    color: white;
}

.interestPaymentSpan{
    padding:10px;
    margin:10px 10px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    border-radius:10px;
    /*background-color: #DC5F00;*/
    color: white;
    background-color: #CF0A0A;
}

.reportsSum{
    padding: 10px;
    background-color: black;
    color: white;
    margin-top: 10px;
    text-align: center;
}
.PassElement{
    display: none;
    margin: 0;
    padding: 0;
}
.logisticsWorkOrderRow{
    padding: 20px 0;
    transition: 0.3s;
    background-color: white;

}